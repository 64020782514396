import moment from 'moment'
var CryptoJS = require("crypto-js");

var Key = 'DZPv7bUD4c82xArE1lfQUIb6'
var Code = 'QUJDREVGR0g='

export const DecryptedList = (response) => {
    var key = CryptoJS.enc.Utf8.parse(Key);
    var iv = CryptoJS.enc.Base64.parse(Code);
    var bytes = CryptoJS.TripleDES.decrypt(response, key, {
      mode: CryptoJS.mode.CBC,
      iv: iv,
    });
  
    return bytes.toString(CryptoJS.enc.Utf8);
  }
  
  // Encrypt Data 
  export const EncryptedList = (text) => {
    var key = CryptoJS.enc.Utf8.parse(Key);
    var iv = CryptoJS.enc.Base64.parse(Code);
    var encoded = CryptoJS.enc.Utf8.parse(text);
    var bytes = CryptoJS.TripleDES.encrypt(encoded, key, {
      mode: CryptoJS.mode.CBC,
      iv: iv,
    });
    return bytes.toString();
  }


  export const DateFormat = (dateStr) => {
    return moment(dateStr).format("DD/MM/YYYY")
  }