import React from 'react'
// import ReactLoading from "react-loading";

const Loader = () => {
  return (
    <>
      {/* <div className='parent-loader'>
        <div className='child-loader'>
          <ReactLoading type="spinningBubbles" color="hsl(218deg 41% 15% / 58%)" />
        </div>
      </div> */}
      <div className="col-md-12"  style={{marginBottom:'-50px'}}>
        <div className="loader11">
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
        </div>
      </div>

    </>
  )
}

export default Loader