import './App.css';
import DataTable from 'react-data-table-component';
import Select from "react-select";
import DatePicker from 'react-datepicker'
import ExcelExData from './ExcelExData';
import "react-datepicker/dist/react-datepicker.css"
import { DateFormat, DecryptedList, EncryptedList } from './Utility';
import axios from 'axios';
import { useState } from 'react';
import Loader from './Loader';

function App() {

  const [value, setValue] = useState({
    DateTo: '', DateTo1: '', Datefrom1: '', Datefrom: '', CompGSTNo: ''
  })
  const [data, setData] = useState([])
  const [loding, setLoding] = useState(false)

  const columns = [
    {
      name: 'InvoiceNo',
      selector: (row) => row.InvoiceNo,
      sortable: true
    },
    {
      name: 'InvoiceDate',
      selector: (row) => row.Invoicedate,
      sortable: true
    },
    {
      name: 'GSTNo',
      selector: (row) => row.GSTNo,
      sortable: true
    },
    {
      name: 'VehicleNo',
      selector: (row) => row.VehicleNo,
      sortable: true
    },

    {
      name: 'PartyName',
      selector: (row) => row.PartyName,
      sortable: true
    },
    {
      name: 'PartyAddress',
      selector: (row) => row.PartyAddress,
      sortable: true
    },
    {
      name: 'SGSTTotal',
      selector: (row) => row.SGSTTotal,
      sortable: true
    },
    {
      name: 'CGSTTotal',
      selector: (row) => row.CGStTotal,
      sortable: true
    },
    {
      name: 'IGSTTotal',
      selector: (row) => row.IGSTTotal,
      sortable: true
    },
    {
      name: 'TotalValuewithTax',
      selector: (row) => row.TotalvaluewithTax,
      sortable: true
    },
    {
      name: 'Qty',
      selector: (row) => row.Qty,
      sortable: true
    },
    {
      name: 'Rate',
      selector: (row) => row.Rate,
      sortable: true
    },
    {
      name: 'Particular',
      selector: (row) => row.Particulars,
      sortable: true
    },

  ]

  const search = async (e) => {
    e.preventDefault()
    setLoding(true)
    const values = EncryptedList(JSON.stringify(value));
    const val = { Data: values };
    try {
      const { data } = await axios.post('https://upgstapi.arustu.com/api/UPBill/GetData_UPBill', val);
      const res = Decrypt_Fetch_Post_Data_Teble(data)
      setLoding(false)
      setData(filterArray(res))
      // console.log(res);
    } catch (error) {
      console.log(error); setLoding(false)
    }
  }

  return (
    <>
      <div className="col-12 col-md-12 col-lg-12  px-3">
        <div className="row  mt-4">
          <div className="col-3 mt-1">
            <div class="text-field">
              <input type="text" name='EmployeeName' onChange={(e) => setValue(pre => { return { ...pre, 'CompGSTNo': e.target.value } })} />
              <label>GST No.</label>
            </div>
          </div>
          <div className="col-2 mb-1">
            <div className="dropdown__box">
              <DatePicker
                id='fromDate'
                name='fromDate'
                className='form-control'
                dateFormat="dd/MM/yyyy"
                timeInputLabel
                isClearable
                onChange={(date) => setValue(pre => { return { ...pre, 'Datefrom': DateFormat(date), 'Datefrom1': date } })}
                selected={value?.Datefrom1}
                placeholderText={'Select...'}
                dropdownMode="select"
                showMonthDropdown
                showYearDropdown
              />
              <label htmlFor="" className='' >From Date</label>
            </div>
          </div>
          <div className="col-3 mb-1">
            <div className="dropdown__box">
              <DatePicker
                id='fromDate'
                name='fromDate'
                className='form-control'
                dateFormat="dd/MM/yyyy"
                timeInputLabel
                isClearable
                onChange={(date) => setValue(pre => { return { ...pre, 'DateTo': DateFormat(date), 'DateTo1': date } })}
                selected={value?.DateTo1}
                placeholderText={'Select...'}
                dropdownMode="select"
                showMonthDropdown
                showYearDropdown
              />
              <label htmlFor="" className='' >To Date</label>
            </div>
          </div>
          <div className="col-3 mt-3 pt-1">
            <button className="btn btn-sm bg-green text-white px-2 py-1" onClick={search} >Save</button>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-12 col-lg-12  px-3">
        <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
          <p className="p-0 m-0 d-flex align-items-center">Name</p>
          <div>
            <ExcelExData csvData={data} fileName={'GSTData'} />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-12  mb-3">
            <div className="table-responsive">
              {
                loding ? <Loader />
                  :
                  <DataTable
                    columns={columns}
                    data={data}
                    dense
                    paginationPerPage={'10'}
                    paginationRowsPerPageOptions={[5, 10, 15]}
                    highlightOnHover
                    noContextMenu
                    pagination
                    responsive
                    subHeaderAlign="right"
                    subHeaderWrap
                    noDataComponent={"There are no data to display"}
                  />
              }

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default App;


export const Decrypt_Fetch_Post_Data_Teble = (res) => {
  const decr = DecryptedList(res.data);
  const TextData = JSON.parse(decr);
  return TextData.Table;
};

export const filterArray = (data) => {
  let keyToRemove = 'ID';
  let keyToRemove1 = 'buyerID';
  let keyToRemove2 = 'ISDelete';
  let keyToRemove3 = 'DeleteDateTime';
  let keyToRemove4 = 'WebbridgeNo';
  let keyToRemove5 = 'CompGSTNo';
  let keyToRemove6 = 'ClientBillID';
  const res = data?.map(item => {
    if (item.hasOwnProperty(keyToRemove)) {
      delete item[keyToRemove];
      delete item[keyToRemove1];
      delete item[keyToRemove2];
      delete item[keyToRemove3];
      delete item[keyToRemove4];
      delete item[keyToRemove5];
      delete item[keyToRemove6];
      return item
    }

  })
  console.log(res);
  return res;
}
